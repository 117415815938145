import React, { useRef } from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import GroupsIcon from "@mui/icons-material/Groups";
import s from "./TenderPage.module.scss";
import IconButton from "@mui/material/IconButton";
import silliconValleyVideo from "../../../../assets/video/Bitcoin Warning_Silicon Valley.mp4";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AccordionExpandIcon } from "../../Accordion/Accordion";
import { ScrollToTop } from "../../../../utils/ScrollToTop";

export const TenderPage = () => {
    React.useEffect(() => {
        ScrollToTop.instant();
        return () => ScrollToTop.instant();
    }, []);
    return (
        <div>
            <Container maxWidth="xl">
                <Box sx={{ flexGrow: 1 }} className={s.box1}>
                    <h2 className={s.heading}>Тендерное сопровождение под ключ</h2>
                    <div className={s.subtitle}>
                        <IconButton color="primary" aria-label="tender">
                            <GroupsIcon className={s.icon_group} />
                        </IconButton>
                        <article className={s.article_text}>
                            <p>
                                <b>Тендерное сопровождение под ключ</b> – это комплекс услуг по участию во всех видах
                                тендеров (аукционах, конкурсах, электронных торгах), в целях заключения контрактов с
                                заказчиками по 44-ФЗ, 223-ФЗ и коммерческими компаниями.
                            </p>
                            <p>
                                Речь пойдет про тендерный аутсорсинг на длительный срок. Если вас интересует
                                сопровождение в конкретном тендере – не теряйте время и обратитесь за консультацией.
                            </p>
                        </article>
                    </div>
                </Box>
            </Container>
            <Container maxWidth="xl">
                <Box sx={{ flexGrow: 1 }} className={s.box2}>
                    <article className={s.article_video}>
                        <IconButton color="primary" aria-label="tender">
                            <FormatQuoteIcon className={s.icon_group} />
                        </IconButton>
                        <q>
                            Конечно же, гарантий победы не бывает. Даже если заказчик очень захочет заключить контракт
                            именно с вами, никаких гарантий он дать не может.
                        </q>
                    </article>
                    <div className={s.player_wrapper}>
                        <video src={silliconValleyVideo} controls></video>
                    </div>
                    <article className={s.article_above_video}>
                        <p>
                            Это смущает некоторых клиентов. Как же так, если нет гарантий победы в тендере, то вместо
                            выгодного контракта можно понести расходы! Да, участие в тендерах – очень рискованно, если
                            вы не можете просчитать свои шансы в начале и не знаете, как их повысить. Это возможно
                            только при грамотной подготовке документов и допуске заявки. ошибок.
                        </p>
                        <p>
                            Закупки всегда конкурентные и победит наиболее эффективный участник. Госзакупки регулируются
                            законами 44-ФЗ и 223-ФЗ. Есть органы контроля, процедуры обжалования, штрафы за нарушения.
                            Гайки постепенно закручиваются. Сейчас даже у организаций, имеющих тендерный отдел или
                            специалиста, периодически возникают сложности в подготовке тендерной документации. Тогда на
                            помощь может прийти тендерный консалтинг от опытной компании, который поможет
                            подстраховаться и избежать дорогостоящих
                        </p>
                    </article>
                    <h2 className={s.heading}>Зависит ли шанс на победу от вида тендера?</h2>
                    <article className={s.article_above_video}>
                        <p>
                            Ответ очевиден – да! Каждый вид тендера имеет свои критерии оценки. И в зависимости от того,
                            чем Вы готовы рискнуть ради победы, можно выбрать более подходящий.
                        </p>

                        <p>
                            Если вы уверенны в себе и своем продукте, готовы идти на уступки и можете снизить цену в
                            случае необходимости, тогда участие в тендерах – хороший рынок сбыта. Когда вы приходите на
                            тендер, то точно знаете, что заказчик купит продукт. Вопрос только в том будете ли это Вы
                            или другой участник. Сопровождение торгов гарантированно поможет донести ваше предложение до
                            заказчика в наиболее выгодном виде.
                        </p>

                        <p>
                            В тендере в форме электронного аукциона цена контракта зависит от уровня конкуренции. Если
                            подобрать хороший аукцион без признаков того, что он сделан под кого-то, придется
                            конкурировать по цене с несколькими компаниями и проявить конкурентоспособность.
                        </p>

                        <p>
                            В тендере в форме открытого конкурса выигрыш дает не низкая цена, а лучшее предложение.
                            Шансов на победу меньше, чем в аукционе, готовить документы сложнее. Побеждать в конкурсах
                            реально при условии подготовки убедительных заявок, подтверждающих опыт и квалификацию
                            участника, грамотного моделирования всех критериев оценки, хорошего технического предложения
                            заказчику. Тендерное сопровождение в конкурсах позволит грамотно подготовить документацию и
                            подобрать наиболее выигрышные критерии для оценки заявок.
                        </p>

                        <p>Запрос котировок по 44-ФЗ не требует помощи в участии в торгах благодаря своей простоте:</p>

                        <div className={s.check_list}>
                            <ul className={s.services_grid3_content}>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>Максимальная цена 500 тыс. руб</div>
                                </li>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Котировочная заявка состоит из одной или нескольких страниц
                                    </div>
                                </li>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Не требуется электронная подпись, котировка подается на бумаге
                                    </div>
                                </li>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Можно массово подавать десятки котировок и выигрывать часть из них
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p>
                            Если речь идет о миллионах и нужны реальные результаты, то самостоятельное обучение или наем
                            фрилансера не приведут к успеху. Участие в тендерах с нуля – непростая задача. Она требует
                            профессионализма, самоотдачи и постоянного развития.
                        </p>
                    </article>
                    <h2 className={s.heading}>
                        Как выбрать компанию, предлагающую комплексное тендерное сопровождение?
                    </h2>
                    <article className={s.article_video}>
                        <IconButton color="primary" aria-label="tender">
                            <FormatQuoteIcon className={s.icon_group} />
                        </IconButton>
                        <q>
                            Если все же Вы решили довериться специалистам, то первая задача – найти компанию на
                            тендерное сопровождение под ключ, которая сможет добиться системного результата.
                        </q>
                    </article>
                    <article className={s.article_above_video}>
                        <p>
                            При выборе стоит обратить внимание на умение подрядчика проводить мониторинг тендеров,
                            анализировать документацию, готовить заявки, проводить торги, заключать контракты. Эти
                            качества нужны в комплексе, а не отдельно. Узнайте предоставлял ли подрядчик подобные услуги
                            ранее, насколько большой опыт у него есть.
                        </p>
                        <p>Рынок компаний, оказывающих услуги по сопровождению тендеров – разношерстный.</p>
                        <p>
                            <b>Непрофильные компании</b>, для которых сопровождение тендеров и аукционов не является
                            основной деятельностью. Они являются посредниками или в штате только один специалист по
                            тендерному сопровождению. Обычно опыт в таких компаниях небольшой, да и спектр услуг уже.
                        </p>
                        <p>
                            <b>Некоторые крупные порталы.</b> Такие компании сладко поют, гарантируют победу в тендере,
                            обещают чудеса после оплаты. Имеют штат специалистов, ориентированный на поток. Вернуть
                            деньги в случае претензий или значительного ущерба крайне сложно даже через суд.
                        </p>
                        <p>
                            <b>Мелкие игроки. </b>Чаще всего за «компанией» стоит фрилансер. Такой подрядчик обычно не
                            возьмется за трудную задачу, имеет намного меньше источников информации и не сможет
                            выполнять большой объем работы в сжатые сроки.
                        </p>
                        <p>
                            Эти категории составляет более 50% рынка тендерного сопровождения. Порог входа на рынок
                            услуг минимальный, а потому привлекательный для многих, в том числе для мошенников.{" "}
                        </p>
                        <p>
                            Отдельно стоят <b>опытные специализированные компании</b>, которые несколько лет работают на
                            рынке, специализируются на тендерном сопровождении, имеют офис, штат от нескольких человек
                            до десятков специалистов. На сайте есть подробное описание услуг, статьи, новости.
                        </p>
                        <p>
                            Компания должна специализироваться именно на направлении тендерного сопровождения, чтобы
                            иметь достаточный объем заказов и загрузку для штата опытных специалистов. Однако, не
                            забывайте внимательно читать договор и обговаривать, что вы хотите получить, прописывать
                            четко какие задачи и обязанности будут стоять перед тендерными специалистами. В тендерах
                            много обмана, лучше верить только документам, если вы не знаете, что это за организация.
                        </p>
                    </article>
                    <h2 className={s.heading}>Наш подход к тендерному сопровождению</h2>
                    <article className={s.article_above_video}>
                        <p>
                            Мы настроены на долгосрочное сотрудничество, комплексные решения, успех наших клиентов и,
                            как следствие, наших сотрудников и акционеров.
                        </p>
                        <p>Сопровождение тендеров под ключ в Москве, в центре Открытые торги организовано так:</p>
                        <div className={s.check_list}>
                            <ul className={s.services_grid3_content}>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Закрепляется один тендерный специалист, который делает основную работу сам, с
                                        ним всегда есть связь. Так вы можете быть уверены, что все делается быстро, а в
                                        общении нет испорченного телефона и некомпетентных людей, туда-сюда передающих
                                        информацию.
                                    </div>
                                </li>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Вам присылают целевые тендеры по заранее оговоренным критериям. Наш специалист
                                        может по каждому дать комментарий почему он вошел в подборку, а вы выбираете
                                        самые интересные для вас тендеры.
                                    </div>
                                </li>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Мы сами готовим и подаем документы, запрашиваем необходимую информацию. Если
                                        появится необходимость в вашем участии, вы получите детальные инструкции что и
                                        как нужно сделать. При желании можно пройти обучение тендерам, понять глубже и
                                        разложить по полочкам многие вещи.
                                    </div>
                                </li>
                                <li className={s.gc_li}>
                                    <div>
                                        <CheckCircleIcon color="primary" style={{ marginRight: "10px" }} />
                                    </div>
                                    <div className={s.descr}>
                                        Успех возможен только при совместных усилиях. На любом этапе вам по запросу
                                        будет предоставлена информация о текущем положении вас, как участника. Так вы
                                        будете знать какие трудности есть, когда вы можете ожидать результаты.
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <p>
                            Отправьте заявку, чтобы узнать подробнее, как мы проводим тендерное сопровождение. Москва и
                            регионы России взаимодействуют с нами по одной схеме. Мы подробно объясним процесс и
                            предоставим проект договора.
                        </p>
                        <AccordionExpandIcon />
                    </article>
                </Box>
            </Container>
        </div>
    );
};
